// TODO: Need better solution for this
const API_URL = process.env.API_URL;

export default {
	uriLogin(id = "") {
		console.log("url", API_URL);
		return `${API_URL}/auth/login/${id}`;
	},

	uriAnalytics(id = "") {
		return `${API_URL}/statistics/dashboard${id}`;
	},

	uriLogs(id = "") {
		return `${API_URL}/statistics/logs/${id}`;
	},

	uriLogout() {
		return `${API_URL}/auth/logout`;
	},

	uriStaff(id = "") {
		return `${API_URL}/staff/${id}`;
	},

	uriCrm(id = "") {
		return `${API_URL}/crm/${id}`;
	},

	uriRecoverPassword(id = "") {
		return `${API_URL}/staff/recover-password/${id}`;
	},

	uriStaffPassword() {
		return `${API_URL}/staff/password`;
	},

	uriStaffEmail() {
		return `${API_URL}/staff/email`;
	},

	uriPages(id = "") {
		return `${API_URL}/pages/${id}`;
	},

	uriEmailTemplate(id = "") {
		return `${API_URL}/email-templates/${id}`;
	},

	uriBanners(id = "") {
		return `${API_URL}/banners/${id}`;
	},

	uriHighlightedSections(id = "") {
		return `${API_URL}/highlighted-sections/${id}`;
	},

	uriFaqs(id = "") {
		return `${API_URL}/faqs/${id}`;
	},

	uriSegments(id = "") {
		return `${API_URL}/segments/${id}`;
	},

	uriUsers(id = "") {
		return `${API_URL}/users/${id}`;
	},

	uriCountries(id = "") {
		return `${API_URL}/countries/${id}`;
	},

	uriSettings() {
		return `${API_URL}/settings/`;
	},

	uriCategories(id = "") {
		return `${API_URL}/categories/${id}`;
	},

	uriPartners(id = "") {
		return `${API_URL}/partners/${id}`;
	},

	uriZones(id = "") {
		return `${API_URL}/zones/${id}`;
	},

	uriContinents(id = "") {
		return `${API_URL}/continents/${id}`;
	},

	uriTaxes(id = "") {
		return `${API_URL}/taxes/${id}`;
	},

	uriShipping(id = "") {
		return `${API_URL}/shippings/${id}`;
	},

	uriProducts(id = "") {
		return `${API_URL}/products/${id}`;
	},

	uriFiles(id = "") {
		return `${API_URL}/files/${id}`;
	},

	uriSeoUrl() {
		return `${API_URL}/seo/validate`;
	},

	uriOrders(id = "") {
		return `${API_URL}/orders/${id}`;
	},

	uriCommissions(id = "") {
		return `${API_URL}/commissions/${id}`;
	},

	uriSearchStaffLogs(id = "") {
		return `${API_URL}/staff-logs/${id}`;
	},

	uriManuals(id = "") {
		return `${API_URL}/manuals/${id}`;
	},

	uriPartnersLevel() {
		return `${API_URL}/cronjobs/calculate-partners-level`;
	},

	uriRevertMonthChanges(month: number, year: number) {
		return `${API_URL}/cronjobs/reset-month?month=${month}&year=${year}`;
	},

	uriGetClosingMonthData() {
		return `${API_URL}/cronjobs/closing-month-data`;
	}
};
