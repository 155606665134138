import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { store } from "store";
import Tree from "react-d3-tree";
import Strings from "utils/strings";
import { setLoader, setTitle } from "store/actions";
import { API, Endpoints } from "utils/api";
import { push } from "connected-react-router";
import { Input, Popover, notification } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Icon } from "components";
import { getSuccessLevelBadge, getSuccessLevelBadgeColor, getTreeColor } from "utils/utils";
const { Search } = Input;

import logo from "assets/images/logo_horizontal_white.png";
import placeholder from "assets/images/placeholders/user.jpg";
import strings from "utils/strings";
import "./styles.scss";
import { CountryType } from "utils/types";

const ALLOWED_ROLES = ["sysadmin", "admin"];
const ALLOWED_LEVELS = [
	"gold",
	"gold30",
	"gold60",
	"gold100",
	"ruby",
	"emerald",
	"diamond",
	"diamond2",
	"diamond4",
	"diamond6",
	"diamond8",
	"special-diamond"
];

interface IProps {
	language: string;
	dispatch: typeof store.dispatch;
	partners: boolean;
	user: any;
	countries: CountryType[];
}

interface Partner {
	name: string;
	attributes?: {
		_id: string;
		email: string;
		phone: string;
	};
	children?: Partner[];
	parent?: {
		name: string;
		_id: string;
		email: string;
	};
	canAdd?: string;
	steps?: Set<string>;
}

interface IState {
	partners: Partner[];
	searchName: string;
	searchEmail: string;
}

class NodeLabel extends React.PureComponent<any> {
	renderIndividualInfo() {
		const { nodeData, countries } = this.props;

		let { background, color } = getSuccessLevelBadgeColor(nodeData.successLevel);

		const countryCallingCode = nodeData.attributes?.phone?.substring(0, 4).replace("+", "");
		let country;

		if (countryCallingCode) country = countries.find((elem: any) => elem.callingCodes.includes(countryCallingCode))?.alpha2Code;

		if (country) {
			nodeData.attributes.country = country.toLowerCase();
		}

		const partnerIsInactive = nodeData.inactive;

		let name = nodeData.name;
		if (Boolean(nodeData.attributes?.userCode)) {
			name += ` (#${nodeData.attributes.userCode})`;
		}

		if (partnerIsInactive) {
			name += ` - ${strings.generic.inactive}`;
		}

		return (
			<div className="NodePartnerInfo" onClick={(e) => e.stopPropagation()}>
				<div className={`PartnerCardHeader${partnerIsInactive ? " --inactive" : ""}`}>
					<div className="PartnerCardHeaderInfo">
						<img src={nodeData.attributes?.photo || placeholder} alt={nodeData.name} />
						<h2>
							{nodeData.attributes?.country ? (
								<div className="react-tel-input">
									<div className="selected-flag">
										<span className={`flag ${nodeData.attributes?.country}`} />
									</div>
								</div>
							) : null}
							{name}
						</h2>
						<h4>{nodeData.attributes?.email}</h4>
						{nodeData.successLevel && !partnerIsInactive && (
							<div className="PartnerSuccessLevel">
								<div className="PartnerSuccessLevelBadge">
									<span style={{ background, color }}>{getSuccessLevelBadge(nodeData.successLevel)}</span>
								</div>
							</div>
						)}
					</div>
					<div className="PartnerCardHeaderAttributes">
						{Boolean(nodeData.referredBy) && (
							<h4 style={{ marginBottom: 5 }}>
								<strong>{Strings.users.referredBy}</strong>
							</h4>
						)}
						{Boolean(nodeData.referredBy) && <h5>{nodeData.referredBy?.name}</h5>}
						{Boolean(nodeData.referredBy) && <h5>{nodeData.referredBy?.email}</h5>}
					</div>
				</div>
				{!nodeData.noInfo && !nodeData.inactive && (
					<div className="PartnerCardData">
						<div className="PartnerCardDataItem">
							<span>{strings.users.personalPurchases}</span>
							<span>{nodeData.points?.cp || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.personalVolume}</span>
							<span>{nodeData.points?.juniorPv || nodeData.points?.pv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.personalVolumeTotal}</span>
							<span>{nodeData.points?.tpv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.personalVolumeAccumulated}</span>
							<span>{nodeData.points?.accumulatedTpv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.leftGroupVolume}</span>
							<span>{nodeData.points?.leftSgv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.rightGroupVolume}</span>
							<span>{nodeData.points?.rightSgv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.leftSpillover}</span>
							<span>{nodeData.points?.leftSpillover || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.rightSpillover}</span>
							<span>{nodeData.points?.rightSpillover || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.leftTotalGroupVolume}</span>
							<span>{nodeData.points?.leftTgv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.rightTotalGroupVolume}</span>
							<span>{nodeData.points?.rightTgv || 0}</span>
						</div>
						<div className="PartnerCardDataItem">
							<span>{strings.users.allowedTgv}</span>
							<span>{nodeData.points?.tgv || 0}</span>
						</div>
					</div>
				)}
			</div>
		);
	}

	render() {
		const { className, nodeData, partners, searched } = this.props;
		const showLogo = nodeData.depth === 0 && !partners && !searched;
		const image = showLogo ? logo : nodeData.attributes?.photo || placeholder;
		const dispatch = store.dispatch;

		if (nodeData.canAdd) {
			return (
				<div style={{ zIndex: nodeData.depth + 1 }} className={className}>
					<div
						className="NodeObject NodeObjectAdd"
						onClick={(e) => {
							if (nodeData.depth) {
								e.preventDefault();
								e.stopPropagation();

								delete nodeData.parent?.parent;

								if (partners) {
									dispatch(
										push("/partner-partners/new", {
											placementSide: nodeData.canAdd,
											underBy: {
												_id: nodeData.parent.attributes._id,
												name: nodeData.parent.name,
												email: nodeData.parent.attributes?.email,
												phone: nodeData.parent.attributes?.phone
											}
										})
									);
								} else {
									dispatch(
										push("/partners/new", {
											placementSide: nodeData.canAdd,
											underBy: {
												_id: nodeData.parent.attributes._id,
												name: nodeData.parent.name,
												email: nodeData.parent.attributes?.email,
												phone: nodeData.parent.attributes?.phone
											}
										})
									);
								}
							}
						}}
					>
						{!partners && nodeData.depth === 0 ? (
							<div className="NodeImageBlock">
								<div className="NodeImage">
									<img src={image} alt={nodeData.name} />
								</div>
							</div>
						) : (
							<div className="NodeImage">
								<Icon name="plus" />
							</div>
						)}
						{nodeData.depth > 0 && (
							<div className="NodeInfo NodeAdd">
								<span>{nodeData.name || strings.settings.addFile}</span>
							</div>
						)}
					</div>
					<button className={`AdditionalNodes${nodeData._children?.length > 0 ? " --visible" : ""}`}>
						<div className="AdditionalNodeIcon">
							{nodeData._collapsed ? <ArrowDownOutlined translate={null} /> : <ArrowUpOutlined translate={null} />}
						</div>
					</button>
				</div>
			);
		}

		if (!nodeData.attributes?._id && !nodeData.noInfo) {
			return (
				<div className={className}>
					<div
						className="NodeObject NodeObjectDisable"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<div className="NodeImage">
							<img src={image} alt={nodeData.name} />
						</div>
					</div>
				</div>
			);
		}

		const clickable = nodeData.depth > 0 && nodeData.name && nodeData.attributes?._id != null;
		const colors = getTreeColor(nodeData.successLevel);
		const badgeStyle = { background: colors.background, color: colors.color };

		const partnerIsInactive = nodeData.inactive;
		if (partnerIsInactive) badgeStyle.background = "#c7c7c7";

		const content = (
			<div
				className={`NodeObject${!clickable ? " NodeObjectDisable" : ""}`}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				{showLogo ? (
					<div className="NodeImageBlock">
						<div className="NodeImage">
							<img src={logo} alt={nodeData.name} />
						</div>
					</div>
				) : (
					<div className="NodeImage">
						<img src={image} alt={nodeData.name} />
					</div>
				)}
				{(partners || nodeData.depth !== 0 || searched) && nodeData.name && (
					<div className="NodeInfo">
						<span style={badgeStyle}>{nodeData.name}</span>
					</div>
				)}
			</div>
		);

		return (
			<div className={className}>
				{nodeData.depth > 0 ? (
					<Popover content={this.renderIndividualInfo()} trigger="hover" placement="bottom">
						{content}
					</Popover>
				) : (
					content
				)}
				<button className={`AdditionalNodes${nodeData._children?.length > 0 ? " --visible" : ""}`}>
					<div className="AdditionalNodeIcon">
						{nodeData._collapsed ? <ArrowDownOutlined translate={null} /> : <ArrowUpOutlined translate={null} />}
					</div>
				</button>
			</div>
		);
	}
}

class BinaryTree extends React.Component<IProps, IState & any> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			partners: [
				{
					name: "Essencia da Vida",
					children: []
				}
			],
			searchName: "",
			searchEmail: ""
		};
	}

	componentDidMount(): void {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.users.partnerTree));

		this.getData();
	}

	componentWillUnmount(): void {
		const { dispatch } = this.props;
		dispatch(setTitle(""));
	}

	async getData(withSearch = false) {
		const { searchName, searchEmail } = this.state;
		const { dispatch, user } = this.props;

		dispatch(setLoader(true));

		const params: { name?: string; email?: string } = {};
		if (withSearch && searchName) params.name = searchName;
		if (withSearch && searchEmail) params.email = searchEmail;

		try {
			const response = await API.get({
				url: Endpoints.uriPartners("binary"),
				params
			});

			if (response?.ok) {
				const { tree } = response.data.results || {};
				if (tree?.length > 0) this.parseBinaryTree({ entries: tree, depth: 0 });

				if ((user.role === "sysadmin" || user.role === "admin") && !withSearch && !searchName && !searchEmail) {
					const partners = [
						{
							name: "Essencia da Vida",
							children: tree,
							noInfo: true
						}
					];
					this.setState({ partners });
				} else if ((user.role === "sysadmin" || user.role === "admin") && withSearch && (searchName || searchEmail)) {
					this.setState({ partners: tree });
				} else {
					this.setState({ partners: tree });
				}
			} else {
				notification.error({
					message: Strings.sidebar.partners,
					description: response?.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err: unknown) {
			notification.error({
				message: Strings.serverErrors.title,
				description: (err as string) || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	parseBinaryTree({ entries, depth }: { entries: Partner[]; depth: number }) {
		const { user } = this.props;

		for (let x = 0; x < entries.length; x++) {
			const entry = entries[x];
			const children = entry.children || [];
			entry.steps = new Set<string>(entry.steps);

			if (depth > 0) entry.steps.add(x === 0 ? "left" : "right");

			for (const child of children) {
				child.steps = new Set<string>(entry.steps);
				child.parent = { name: entry.name, _id: entry.attributes!._id, email: entry.attributes!.email };
			}

			if (entry.steps.size === 1 && !entry.attributes?._id) entry.canAdd = x === 0 ? "left" : "right";
			else if ((ALLOWED_ROLES.includes(user.role) || ALLOWED_LEVELS.includes(user.partner?.successLevel)) && !entry.attributes?._id)
				entry.canAdd = x === 0 ? "left" : "right";

			if (children.length > 0) {
				this.parseBinaryTree({ entries: entry.children as Partner[], depth: depth + 1 });
			}
		}
	}

	onSearch = async (value: string) => {
		await this.getData(value ? true : false);
		this.setState({ withSearch: value ? true : false });
	};

	renderTree() {
		const { partners, searchName = "", searchEmail = "", withSearch } = this.state;
		const { partners: partnersProp, countries } = this.props;

		return (
			<div className="PartnerBinaryTree">
				<div className="PartnerSearch">
					<Search
						placeholder={Strings.generic.searchPartner}
						onChange={(e) => this.setState({ searchName: e.target.value, searchEmail: "" })}
						onSearch={this.onSearch}
						style={{ width: 250 }}
						enterButton
						value={searchName}
					/>
				</div>
				<div className="PartnerEmailSearch">
					<Search
						placeholder={Strings.generic.searchEmail}
						onChange={(e) => this.setState({ searchEmail: e.target.value, searchName: "" })}
						onSearch={this.onSearch}
						style={{ width: 250 }}
						enterButton
						value={searchEmail}
					/>
				</div>
				{partners?.length > 0 && (
					<Tree
						data={partners}
						nodeSvgShape={{ shape: "rect" }}
						pathFunc="step"
						separation={{ siblings: 2, nonSiblings: 2 }}
						orientation="vertical"
						translate={{
							x: (document.querySelector(".PartnerBinaryTree")?.scrollWidth || window.innerWidth - 220) / 2,
							y: 100
						}}
						allowForeignObjects={true}
						nodeLabelComponent={{
							render: (
								<NodeLabel className="PartnerNode" partners={partnersProp} searched={withSearch} countries={countries} />
							),
							foreignObjectWrapper: {
								width: 250,
								height: 120,
								y: -55,
								x: -125
							}
						}}
						initialDepth={0.01}
					/>
				)}
				{partners?.length === 0 && (
					<div className="PartnerBinaryTreeEmpty">
						<h2>{Strings.users.noPartnersFound}</h2>
					</div>
				)}
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.users.partnerTree}</title>
					<meta name="description" content="Visual representation of partners referral binary tree" />
				</Helmet>
				{this.renderTree()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: { language: string; user: any; countries: CountryType[] }) => ({
	language: state.language,
	user: state.user,
	countries: state.countries
});

export default connect(mapStateToProps)(BinaryTree);
