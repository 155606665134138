/*
*
* ConsecutiveMonthSimulation
*
*/

import React from "react";
import { connect } from "react-redux";
import { setLastLogUpdate, setLoader, setMonthLogs } from "store/actions";
import { Helmet } from "react-helmet";
import Strings from "utils/strings";
import "./styles.scss";
import { Button, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { API, Endpoints } from "utils/api";

export class ConsecutiveMonthSimulation extends React.Component<any, any> {
	constructor(props: any) {
		console.log("🚀 ~ MonthSimulation ~ constructor ~ props:", props);
		super(props);

		this.state = {
			logs: props?.monthLogs || "",
			lastUpdateDate: props?.lastLogUpdate || "",
			selectedMonth: null,
			currentSystemDate: null,
			monthsClosed: [],
		};
	}

	componentDidMount(): void {
		this.getData();
	}

	async getData() {
		const { dispatch } = this.props;

		

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriGetClosingMonthData()
		});

		if (response.ok) {
			const { data } = response.data.results;

			this.setState({
				currentSystemDate: data?.currentDateInClosing,
				monthsClosed: data?.monthsManuallyClosed
			});
		}

		dispatch(setLoader(false));
	}

	async getPartnersLevel() {
		const { dispatch } = this.props;
		const { logs } = this.state;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriPartnersLevel()
		});

		const currentDatetime = new Date().toISOString();

		let newLogs = logs ? logs + "\n\n" + response.data.results?.logsEntry?.logs : response.data.results?.logsEntry?.logs;

		if (response.ok) {
			this.setState({
				lastUpdateDate: currentDatetime,
				logs: newLogs
			}, () => this.getData());
		}

		dispatch(setMonthLogs(newLogs));
		dispatch(setLastLogUpdate(currentDatetime));
		dispatch(setLoader(false));
	}

	async revertChanges(month: number, year: number) {
		const { dispatch } = this.props;
		const { logs } = this.state;

		dispatch(setLoader(true));

		const response = await API.get({
			url: Endpoints.uriRevertMonthChanges(month, year)
		});

		const currentDatetime = new Date().toISOString();

		const formattedDate = new Date(currentDatetime).toLocaleString();

		let newLogs = logs ? logs + `\nMês Revertido... (${formattedDate}) ` : `Mês Revertido... (${formattedDate}) `;

		if (response.ok) {
			this.setState({
				selectedMonth: null,
				lastUpdateDate: currentDatetime,
				logs: newLogs
			}, () => this.getData());
		}
		dispatch(setMonthLogs(newLogs));
		dispatch(setLastLogUpdate(currentDatetime));
		dispatch(setLoader(false));
	}

	cleanConsole() {
		const { dispatch } = this.props;
		const { logs, lastUpdateDate } = this.state;
		this.setState({ logs: "" });
		dispatch(setMonthLogs(""));
		// dispatch(setLastLogUpdate("")); => To delete the last update date from the store
	}

	render() {
		const { logs, lastUpdateDate, selectedMonth, monthsClosed, currentSystemDate } = this.state;
		console.log("🚀 ~ ConsecutiveMonthSimulation ~ render ~ monthsClosed:", monthsClosed);
		
		// Convert months data into Select options
		const monthOptions = monthsClosed?.map((item: any) => {
			const date = new Date(item.year, item.month - 1);
			const monthName = date.toLocaleString('pt-BR', { month: 'long' });
			const formattedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
			
			return {
				value: JSON.stringify(item),  // Store as string
				label: `${formattedMonthName} ${item.year}`
			};
		});
		

		const formattedDate = lastUpdateDate ? new Date(lastUpdateDate).toLocaleString() : "";

		let currentMonthDate = currentSystemDate ? new Date(this.state.currentSystemDate) : new Date();

		const monthsInPortuguese = [
			'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
			'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
		  ];
		  
		  
		const monthName = monthsInPortuguese[currentMonthDate.getMonth()];

		return (
			<React.Fragment>
				<Helmet>
					<meta name="description" content="Description of MonthSimulation" />
				</Helmet>
				<div className="TableComponent">
					<div className="TableMainHeader">
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<span className="MainHeaderTitle">{Strings.monthSimulation.consecutiveMonthClosing}</span>
							<p><b>{`Mês atual do sistema: `}</b>{`${monthName}`}</p>
						</div>
						<hr className="hr"></hr>
					</div>
					<div>
						<text>{Strings.monthSimulation.consecutiveMonthClosingDescription}</text>
						<div style={{ marginTop: "20px" }}>
							<Button onClick={() => this.getPartnersLevel()} type="primary">
								{Strings.monthSimulation.closeMonth}
							</Button>
							{/* Month selection dropdown */}
							<Select
								defaultValue="Mês" 
								style={{ width: 120, marginLeft: 10 }}
								onChange={(value) => {
									// Parse the string back to object when selected
									const selected = JSON.parse(value);
									console.log("Selected month:", selected);
									this.setState({ selectedMonth: selected });
								}}				
								options={monthOptions}
								optionFilterProp="label"
								labelInValue={false}
							/>
							<Button disabled={!selectedMonth} onClick={() => this.revertChanges(selectedMonth?.month, selectedMonth?.year)} style={{ marginLeft: "10px" }} type="default">
								{Strings.monthSimulation.revertMonth}
							</Button>
						</div>
					</div>
				</div>
				<div style={{ height: "100vh", marginBottom: "50px", marginTop: "10px" }} className="TableComponent">
					<div className="TableMainHeader" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<span className="MainHeaderTitle">{Strings.monthSimulation.promotionsAndDemotionsLogs}</span>
						{formattedDate && (
							<i>
								{Strings.monthSimulation.lastUpdate}: {formattedDate}
							</i>
						)}
					</div>
					<TextArea
						readOnly
						value={logs}
						className="custom-textarea"
						style={{
							display: "flex",
							height: "90%",
							marginTop: "10px"
						}}
					/>
					<div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "10px" }}>
						<Button onClick={() => this.cleanConsole()} disabled={!logs} type="primary">
							{Strings.monthSimulation.cleanUp}
						</Button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	monthLogs: state.monthLogs,
	lastLogUpdate: state.lastLogUpdate
});

export default connect(mapStateToProps)(ConsecutiveMonthSimulation);