// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--primary-color: #00b5b0;--light-primary: #19f1eb;--secondary-color: #22a8dd;--light-secondary: #b8e4f0;--app-background-color: whitesmoke;--logo-container-background: #151515;--text-color: #333;--gradient: linear-gradient(180deg, #00b5b0 1.04%, #19f1eb 100%);--gradient-border: linear-gradient(90deg, #00b5b0 1.04%, #19f1eb60 100%)}.MainHeaderTitle{font-size:1.5em;display:block;font-weight:bold;margin-right:auto;color:#00b5b0}.hr{border:none;border-top:.5px solid #f0f0f0}.TextAreaContainerStyle{margin-top:10px;height:100vh;margin-bottom:50px}.TextAreaStyle{height:100%;width:100%;padding:10px;font-size:1.2em;border:1px solid #ccc;border-radius:5px;resize:none;font-family:inherit}.custom-textarea{font-size:13px !important;font-family:Poppins}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#00b5b0",
	"lightPrimary": "#19f1eb",
	"secondaryColor": "#22a8dd",
	"lightSecondary": "#b8e4f0"
};
module.exports = exports;
