/*
 *
 * Settings
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, setBreadcrumb } from "store/actions";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { Icon, ContentWrapper } from "components";
import { FileUnknownOutlined, ToTopOutlined } from "@ant-design/icons";

import Strings from "utils/strings";
import "./styles.scss";

export class Settings extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));
		dispatch(setBreadcrumb(null));
	}

	goTo(url: string) {
		const { dispatch } = this.props;
		dispatch(push(url));
	}

	renderOptions() {
		const { user } = this.props;
		const isDev2Grow = (Boolean(user) && user.role === "sysadmin") || false;

		const options = [
			{
				icon: "location",
				url: "settings/zones",
				title: Strings.settings.zones,
				subtitle: Strings.settings.zonesSubTitle
			},
			{
				icon: "tax-settings",
				url: "settings/taxes",
				title: Strings.settings.taxes,
				subtitle: Strings.settings.taxesSubTitle
			},
			{
				icon: "delivery",
				url: "settings/shippings",
				title: Strings.settings.shippings,
				subtitle: Strings.settings.shippingsSubTitle
			},
			{
				icon: "tax-settings",
				url: "settings/bonuses",
				title: Strings.settings.bonuses,
				subtitle: Strings.settings.bonusesSubTitle
			},
			{
				icon: "points",
				url: "settings/incentives",
				title: Strings.settings.incentives,
				subtitle: Strings.settings.incentivesSubTitle
			},
			{
				icon: <ToTopOutlined translate={null} />,
				iconType: "antd",
				url: "settings/success-ladder",
				title: Strings.settings.successLadder,
				subtitle: Strings.settings.successLadderSubTitle
			},
			{
				icon: "text-files",
				url: "settings/pages",
				title: Strings.settings.pages,
				subtitle: Strings.settings.pagesSubTitle
			},
			{
				icon: "mail-envelope",
				url: "settings/email-templates",
				title: Strings.settings.emailTemplates,
				subtitle: Strings.settings.emailsSubTitle
			},
			{
				icon: "support-tickets",
				url: "settings/faqs",
				title: Strings.settings.faqs,
				subtitle: Strings.settings.faqSubTitle
			},
			{
				icon: <FileUnknownOutlined translate={null} />,
				iconType: "antd",
				url: "settings/files",
				title: Strings.settings.files,
				subtitle: Strings.settings.filesSubTitle
			},
			{
				icon: "duplicate",
				url: "settings/manuals",
				title: Strings.settings.manuals,
				subtitle: Strings.settings.manualsSubTitle
			},
			{
				icon: "duplicate",
				url: "settings/ethics",
				title: Strings.settings.ethics,
				subtitle: Strings.settings.ethicsSubTitle
			},
			{
				icon: "duplicate",
				url: "settings/month-simulation",
				title: "Fecho do mês",
				subtitle: "Feche um mês manualmente na plataforma, para testar os cálculos de nível de sucesso"
			},
			{
				icon: "duplicate",
				url: "settings/consecutive-month-simulation",
				title: "Fecho de meses consecutivos",
				subtitle: "Feche vários meses consecutivos na plataforma, para testar os cálculos de nível de sucesso"
			}
		];

		if (isDev2Grow)
			options.push({
				icon: "tablet",
				url: "settings/app-settings",
				title: Strings.settings.appSettings,
				subtitle: Strings.settings.appSettingsTitle
			});

		return options.map((option) => (
			<Col key={option.title} xs={24} lg={12} xl={8}>
				<ContentWrapper extraClass="SettingsCards">
					<div className="SettingsOptionBlock" onClick={() => this.goTo(option.url)}>
						<div className="SettingsIcon">
							{option.iconType === "antd" ? option.icon : <Icon name={option.icon as string} />}
						</div>
						<div className="SettingsOptionText">
							<div className="SettingsOptionTitles">
								<span className="SettingsOptionTitle">{option.title}</span>
								<span className="SettingsOptionSubTitle">{option.subtitle}</span>
							</div>
							<div className="SettingsOptionNavigate">
								<span>{Strings.formatString(Strings.generic.clickHereTo, Strings.generic.edit.toLowerCase())}</span>
							</div>
						</div>
					</div>
				</ContentWrapper>
			</Col>
		));
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.settings}</title>
					<meta name="description" content="Description of Settings" />
				</Helmet>
				<Row gutter={[25, 25]}>{this.renderOptions()}</Row>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user
});

export default connect(mapStateToProps)(Settings);
